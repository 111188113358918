import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          staticClass: "ma-6",
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.updateProfile()
            },
          },
        },
        [
          _c(
            VRow,
            { attrs: { align: "center" } },
            [
              _c(
                VCol,
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(VTextField, {
                    staticClass: "purple-input my-input-style",
                    attrs: { label: _vm.$t("name") },
                    model: {
                      value: _vm.Profile.Name,
                      callback: function ($$v) {
                        _vm.$set(_vm.Profile, "Name", $$v)
                      },
                      expression: "Profile.Name",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(VTextField, {
                    staticClass: "purple-input my-input-style",
                    attrs: { label: _vm.$t("last_name") },
                    model: {
                      value: _vm.Profile.LastName,
                      callback: function ($$v) {
                        _vm.$set(_vm.Profile, "LastName", $$v)
                      },
                      expression: "Profile.LastName",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "12", md: "12" } },
                [
                  _c(VTextField, {
                    staticClass: "purple-input my-input-style",
                    attrs: {
                      rules: [
                        _vm.special_valid,
                        _vm.accent_valid,
                        _vm.email_valid,
                      ],
                      label: _vm.$t("email"),
                    },
                    model: {
                      value: _vm.Profile.Email,
                      callback: function ($$v) {
                        _vm.$set(_vm.Profile, "Email", $$v)
                      },
                      expression: "Profile.Email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.Profile.ProfileDone == false || !_vm.checkComplyCube
            ? _c(
                "div",
                [
                  _c(
                    VAlert,
                    {
                      staticClass: "registerAlert",
                      attrs: { dense: "", color: "blue", elevation: "3" },
                    },
                    [
                      _c(
                        VIcon,
                        {
                          staticClass: "mr-2",
                          attrs: { color: "white", "x-large": "" },
                        },
                        [_vm._v("mdi-alert-circle-outline ")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "text_alert",
                          staticStyle: { "font-size": "14px" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("register_alert")) + " ")]
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "btn_alert ml-1",
                          attrs: {
                            "data-test": "Alpha:Basic:BtnCompleteRegister",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleInvestorGoCompleteProfile(
                                _vm.$route.path
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("complete_registration")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.Profile.Type == _vm.UserTypeEnum.Investor
            ? _c(
                VExpansionPanels,
                [
                  _c(
                    VExpansionPanel,
                    [
                      _c(
                        VExpansionPanelHeader,
                        {
                          staticClass: "dark-color",
                          attrs: {
                            disabled: _vm.disabled_field,
                            color: "white",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("contact_profile")) + " ")]
                      ),
                      _c(
                        VExpansionPanelContent,
                        { attrs: { color: "white" } },
                        [
                          _vm.Profile.Type == _vm.UserTypeEnum.Investor
                            ? _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" },
                                    },
                                    [
                                      _c(VCombobox, {
                                        staticClass: "my-input-style",
                                        attrs: {
                                          dense: "",
                                          items: _vm.countries,
                                          "item-text": "name",
                                          "return-object": "",
                                          rules: [_vm.required],
                                          label: _vm.$t("nationality"),
                                        },
                                        on: {
                                          change: _vm.nationality_code_changed,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selection",
                                              fn: function (data) {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "mx-2",
                                                      staticStyle: {
                                                        "text-transform":
                                                          "none",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(data.item.name)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          964366794
                                        ),
                                        model: {
                                          value: _vm.nationalitySelected,
                                          callback: function ($$v) {
                                            _vm.nationalitySelected = $$v
                                          },
                                          expression: "nationalitySelected",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" },
                                    },
                                    [
                                      _vm.Profile.Nationality == "Brazil"
                                        ? _c(VTextField, {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value:
                                                  _vm.Profile.JuridicalType ==
                                                  _vm.JuridicalTypeEnum
                                                    .NaturalPerson
                                                    ? "###.###.###-##"
                                                    : "##.###.###/####-##",
                                                expression:
                                                  "\n                  Profile.JuridicalType == JuridicalTypeEnum.NaturalPerson\n                    ? '###.###.###-##'\n                    : '##.###.###/####-##'\n                ",
                                              },
                                            ],
                                            staticClass: "my-input-style",
                                            attrs: {
                                              dense: "",
                                              type: "tel",
                                              rules: [
                                                _vm.required,
                                                _vm.social_number_valid,
                                              ],
                                              label:
                                                _vm.Profile.JuridicalType ==
                                                _vm.JuridicalTypeEnum
                                                  .NaturalPerson
                                                  ? _vm.$t("cpf")
                                                  : _vm.$t("company_id"),
                                            },
                                            on: {
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "space",
                                                    32,
                                                    $event.key,
                                                    [" ", "Spacebar"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                              },
                                            },
                                            model: {
                                              value: _vm.Profile.SocialNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.Profile,
                                                  "SocialNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "Profile.SocialNumber",
                                            },
                                          })
                                        : _c(VTextField, {
                                            staticClass: "my-input-style",
                                            attrs: {
                                              dense: "",
                                              type: "tel",
                                              rules: [_vm.required],
                                              label:
                                                _vm.Profile.JuridicalType ==
                                                _vm.JuridicalTypeEnum
                                                  .NaturalPerson
                                                  ? _vm.$t("passport")
                                                  : _vm.$t("company_id"),
                                            },
                                            on: {
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "space",
                                                    32,
                                                    $event.key,
                                                    [" ", "Spacebar"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                              },
                                            },
                                            model: {
                                              value: _vm.Profile.SocialNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.Profile,
                                                  "SocialNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "Profile.SocialNumber",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "##/##/####",
                                            expression: "'##/##/####'",
                                          },
                                        ],
                                        staticClass: "my-input-style",
                                        attrs: {
                                          hint: "dd/mm/yyyy",
                                          label:
                                            _vm.Profile.JuridicalType ==
                                            _vm.JuridicalTypeEnum.NaturalPerson
                                              ? _vm.$t("birth_date")
                                              : _vm.$t("establishment_date"),
                                          "prepend-inner-icon": "mdi-calendar",
                                          rules: [
                                            _vm.required,
                                            _vm.validDate,
                                            _vm.min18Years,
                                          ],
                                        },
                                        on: { change: _vm.birth_changed },
                                        model: {
                                          value: _vm.date,
                                          callback: function ($$v) {
                                            _vm.date = $$v
                                          },
                                          expression: "date",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "my-input-style",
                                        attrs: { label: _vm.$t("celphone") },
                                        on: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "space",
                                                32,
                                                $event.key,
                                                [" ", "Spacebar"]
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.preventDefault()
                                          },
                                        },
                                        model: {
                                          value: _vm.Profile.CelPhone,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "CelPhone",
                                              $$v
                                            )
                                          },
                                          expression: "Profile.CelPhone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "my-input-style",
                                        attrs: { label: _vm.$t("home_phone") },
                                        on: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "space",
                                                32,
                                                $event.key,
                                                [" ", "Spacebar"]
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.preventDefault()
                                          },
                                        },
                                        model: {
                                          value: _vm.Profile.HomePhone,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "HomePhone",
                                              $$v
                                            )
                                          },
                                          expression: "Profile.HomePhone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "8" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("address") },
                                        model: {
                                          value: _vm.Profile.Address,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "Address",
                                              $$v
                                            )
                                          },
                                          expression: "Profile.Address",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "2" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("number") },
                                        model: {
                                          value: _vm.Profile.AddressNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "AddressNumber",
                                              $$v
                                            )
                                          },
                                          expression: "Profile.AddressNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "2" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("complement") },
                                        model: {
                                          value: _vm.Profile.AddressComplement,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "AddressComplement",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "Profile.AddressComplement",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("state") },
                                        model: {
                                          value: _vm.Profile.State,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.Profile, "State", $$v)
                                          },
                                          expression: "Profile.State",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: {
                                          label: _vm.$t("neighborhood"),
                                        },
                                        model: {
                                          value: _vm.Profile.Neighborhood,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "Neighborhood",
                                              $$v
                                            )
                                          },
                                          expression: "Profile.Neighborhood",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("country") },
                                        model: {
                                          value: _vm.Profile.Country,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "Country",
                                              $$v
                                            )
                                          },
                                          expression: "Profile.Country",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("city") },
                                        model: {
                                          value: _vm.Profile.City,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.Profile, "City", $$v)
                                          },
                                          expression: "Profile.City",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("post_code") },
                                        model: {
                                          value: _vm.Profile.PostCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "PostCode",
                                              $$v
                                            )
                                          },
                                          expression: "Profile.PostCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "mx-auto",
                                          staticStyle: { color: "var(--dark)" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("account_type")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        VRadioGroup,
                                        {
                                          attrs: { center: "" },
                                          model: {
                                            value: _vm.Profile.JuridicalType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.Profile,
                                                "JuridicalType",
                                                $$v
                                              )
                                            },
                                            expression: "Profile.JuridicalType",
                                          },
                                        },
                                        [
                                          _c(VRadio, {
                                            attrs: {
                                              value:
                                                _vm.JuridicalTypeEnum
                                                  .NaturalPerson,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color:
                                                              "var(--dark)",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "fisical_person"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              1066688523
                                            ),
                                          }),
                                          _c(VRadio, {
                                            attrs: {
                                              value:
                                                _vm.JuridicalTypeEnum
                                                  .LegalPerson,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color:
                                                              "var(--dark)",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "legal_person"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              1686139251
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.Profile.JuridicalType ==
                  _vm.JuridicalTypeEnum.NaturalPerson
                    ? _c(
                        VExpansionPanel,
                        [
                          _c(
                            VExpansionPanelHeader,
                            {
                              staticClass: "dark-color",
                              attrs: {
                                disabled: _vm.disabled_field,
                                color: "white",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("patrimonial_info")) + " "
                              ),
                            ]
                          ),
                          _c(
                            VExpansionPanelContent,
                            { attrs: { color: "white" } },
                            [
                              _vm.Profile.Type == _vm.UserTypeEnum.Investor
                                ? _c(
                                    VRow,
                                    [
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(VTextField, {
                                            directives: [
                                              {
                                                name: "money",
                                                rawName: "v-money",
                                                value: _vm.money,
                                                expression: "money",
                                              },
                                            ],
                                            staticClass:
                                              "purple-input my-input-style",
                                            attrs: {
                                              label: _vm.$t("total_assets"),
                                            },
                                            model: {
                                              value: _vm.Profile.TotalAssets,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.Profile,
                                                  "TotalAssets",
                                                  $$v
                                                )
                                              },
                                              expression: "Profile.TotalAssets",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(VCol, { attrs: { cols: "12" } }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "var(--dark)",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("assets_origins")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "mt-4 mx-12" },
                                          [
                                            _c(
                                              VRow,
                                              { attrs: { align: "center" } },
                                              _vm._l(
                                                _vm.assetOptions,
                                                function (asset, i) {
                                                  return _c(
                                                    VCol,
                                                    {
                                                      key: i,
                                                      staticClass: "my-0 py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c(VCheckbox, {
                                                        staticClass:
                                                          "my-0 py-0",
                                                        attrs: {
                                                          value: asset.id,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "label",
                                                              fn: function () {
                                                                return [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "var(--dark)",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            asset.key
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.selectedAssets,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selectedAssets =
                                                              $$v
                                                          },
                                                          expression:
                                                            "selectedAssets",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    VExpansionPanel,
                    [
                      _vm.Profile.JuridicalType ==
                      _vm.JuridicalTypeEnum.NaturalPerson
                        ? _c(
                            VExpansionPanelHeader,
                            {
                              staticClass: "dark-color",
                              attrs: {
                                disabled: _vm.disabled_field,
                                color: "white",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("personal_docs")) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        VExpansionPanelContent,
                        { attrs: { color: "white" } },
                        [
                          _vm.Profile.Type == _vm.UserTypeEnum.Investor
                            ? _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "dark-color" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("document_type")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        VRow,
                                        { staticClass: "my-4 mx-12" },
                                        [
                                          _c(
                                            VRadioGroup,
                                            {
                                              attrs: { center: "" },
                                              model: {
                                                value: _vm.Profile.DocumentType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.Profile,
                                                    "DocumentType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "Profile.DocumentType",
                                              },
                                            },
                                            [
                                              _c(VRadio, {
                                                attrs: { value: 0 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("rg")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4117849935
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 1 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("cnh")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3985088063
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 2 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "passport"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3524110290
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: {
                                          label: _vm.$t("document_number"),
                                        },
                                        model: {
                                          value: _vm.Profile.DocumentNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "DocumentNumber",
                                              $$v
                                            )
                                          },
                                          expression: "Profile.DocumentNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: {
                                          label: _vm.$t("issuing_body"),
                                        },
                                        model: {
                                          value: _vm.Profile.Issuer,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.Profile, "Issuer", $$v)
                                          },
                                          expression: "Profile.Issuer",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("state") },
                                        model: {
                                          value: _vm.Profile.IssuerState,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "IssuerState",
                                              $$v
                                            )
                                          },
                                          expression: "Profile.IssuerState",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        VMenu,
                                        {
                                          ref: "menuDateIssuer",
                                          attrs: {
                                            "close-on-content-click": false,
                                            "return-value": _vm.dateIssuer,
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "min-width": "290px",
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              _vm.dateIssuer = $event
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              _vm.dateIssuer = $event
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      VTextField,
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "my-input-style",
                                                            attrs: {
                                                              label:
                                                                _vm.$t(
                                                                  "expedition_date"
                                                                ),
                                                              "prepend-inner-icon":
                                                                "mdi-calendar",
                                                              readonly: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.dateIssuer,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.dateIssuer =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "dateIssuer",
                                                            },
                                                          },
                                                          "v-text-field",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3814002796
                                          ),
                                          model: {
                                            value: _vm.menuDateIssuer,
                                            callback: function ($$v) {
                                              _vm.menuDateIssuer = $$v
                                            },
                                            expression: "menuDateIssuer",
                                          },
                                        },
                                        [
                                          _c(VDatePicker, {
                                            staticClass: "mx-auto",
                                            on: {
                                              change: function ($event) {
                                                _vm.$refs.menuDateIssuer.save(
                                                  _vm.dateIssuer
                                                )
                                                _vm.menuDateIssuer = false
                                                _vm.date_issuer_changed()
                                              },
                                            },
                                            model: {
                                              value: _vm.dateIssuer,
                                              callback: function ($$v) {
                                                _vm.dateIssuer = $$v
                                              },
                                              expression: "dateIssuer",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("mother_name") },
                                        model: {
                                          value: _vm.Profile.MotherName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "MotherName",
                                              $$v
                                            )
                                          },
                                          expression: "Profile.MotherName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("father_name") },
                                        model: {
                                          value: _vm.Profile.FatherName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "FatherName",
                                              $$v
                                            )
                                          },
                                          expression: "Profile.FatherName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "dark-color" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "legal_representative_question"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        VRow,
                                        { staticClass: "my-4 mx-12" },
                                        [
                                          _c(
                                            VRadioGroup,
                                            {
                                              attrs: { center: "" },
                                              model: {
                                                value:
                                                  _vm.Profile
                                                    .HasLegalRepresentative,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.Profile,
                                                    "HasLegalRepresentative",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "Profile.HasLegalRepresentative",
                                              },
                                            },
                                            [
                                              _c(VRadio, {
                                                attrs: { value: true },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("yes")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  661222869
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: false },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("no")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1351614811
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.Profile.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson
                    ? _c(
                        VExpansionPanel,
                        [
                          _c(
                            VExpansionPanelHeader,
                            {
                              staticClass: "dark-color",
                              attrs: {
                                disabled: _vm.disabled_field,
                                color: "white",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("juridical_info")) + " "
                              ),
                            ]
                          ),
                          _c(
                            VExpansionPanelContent,
                            { attrs: { color: "white" } },
                            [
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: {
                                          label: _vm.$t("company_name"),
                                        },
                                        model: {
                                          value: _vm.Profile.CompanyName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "CompanyName",
                                              $$v
                                            )
                                          },
                                          expression: "Profile.CompanyName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: {
                                          label: _vm.$t("state_registration"),
                                        },
                                        model: {
                                          value: _vm.Profile.StateRegistration,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "StateRegistration",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "Profile.StateRegistration",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: {
                                          label: _vm.$t(
                                            "main_economic_activity"
                                          ),
                                        },
                                        model: {
                                          value:
                                            _vm.Profile.MainEconomicActivity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "MainEconomicActivity",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "Profile.MainEconomicActivity",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("tax_address") },
                                        model: {
                                          value: _vm.Profile.TaxAddress,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "TaxAddress",
                                              $$v
                                            )
                                          },
                                          expression: "Profile.TaxAddress",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("cnae") },
                                        model: {
                                          value: _vm.Profile.CNAE,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.Profile, "CNAE", $$v)
                                          },
                                          expression: "Profile.CNAE",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.Profile.JuridicalType ==
                    _vm.JuridicalTypeEnum.LegalPerson &&
                  _vm.Profile.HasLegalRepresentative
                    ? _c(
                        VExpansionPanel,
                        [
                          _c(
                            VExpansionPanelHeader,
                            {
                              staticClass: "dark-color",
                              attrs: {
                                disabled: _vm.disabled_field,
                                color: "white",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("legal_representative_header")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            VExpansionPanelContent,
                            { attrs: { color: "white" } },
                            [
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("name") },
                                        model: {
                                          value:
                                            _vm.Profile.LegalRepresentative
                                              .Name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile.LegalRepresentative,
                                              "Name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "Profile.LegalRepresentative.Name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("cpf") },
                                        model: {
                                          value:
                                            _vm.Profile.LegalRepresentative
                                              .SocialNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile.LegalRepresentative,
                                              "SocialNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "Profile.LegalRepresentative.SocialNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("link_type")) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        VRow,
                                        { staticClass: "my-4 mx-12" },
                                        [
                                          _c(
                                            VRadioGroup,
                                            {
                                              attrs: { center: "" },
                                              model: {
                                                value:
                                                  _vm.Profile
                                                    .LegalRepresentative
                                                    .LinkType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.Profile
                                                      .LegalRepresentative,
                                                    "LinkType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "Profile.LegalRepresentative.LinkType",
                                              },
                                            },
                                            [
                                              _c(VRadio, {
                                                attrs: { value: 0 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "legal_representative"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4243133909
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 1 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "attorney"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3204358580
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("marital_status")) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        VRow,
                                        { staticClass: "my-4 mx-12" },
                                        [
                                          _c(
                                            VRadioGroup,
                                            {
                                              attrs: { center: "" },
                                              model: {
                                                value:
                                                  _vm.Profile
                                                    .LegalRepresentative
                                                    .MaritalStatus,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.Profile
                                                      .LegalRepresentative,
                                                    "MaritalStatus",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "Profile.LegalRepresentative.MaritalStatus",
                                              },
                                            },
                                            [
                                              _c(VRadio, {
                                                attrs: { value: 0 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "single"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  127828032
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 1 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "married"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  705419646
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 2 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "widower"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1329492943
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 3 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "divorced"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2925929822
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("nationality") },
                                        model: {
                                          value:
                                            _vm.Profile.LegalRepresentative
                                              .Nationality,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile.LegalRepresentative,
                                              "Nationality",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "Profile.LegalRepresentative.Nationality",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("profession") },
                                        model: {
                                          value:
                                            _vm.Profile.LegalRepresentative
                                              .Profession,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile.LegalRepresentative,
                                              "Profession",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "Profile.LegalRepresentative.Profession",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("document_type")) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        VRow,
                                        { staticClass: "my-4 mx-12" },
                                        [
                                          _c(
                                            VRadioGroup,
                                            {
                                              attrs: { center: "" },
                                              model: {
                                                value:
                                                  _vm.Profile
                                                    .LegalRepresentative
                                                    .DocumentType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.Profile
                                                      .LegalRepresentative,
                                                    "DocumentType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "Profile.LegalRepresentative.DocumentType",
                                              },
                                            },
                                            [
                                              _c(VRadio, {
                                                attrs: { value: 0 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("rg")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4117849935
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 1 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("cnh")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3985088063
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 2 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "passport"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3524110290
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: {
                                          label: _vm.$t("document_number"),
                                        },
                                        model: {
                                          value:
                                            _vm.Profile.LegalRepresentative
                                              .DocumentNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile.LegalRepresentative,
                                              "DocumentNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "Profile.LegalRepresentative.DocumentNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: {
                                          label: _vm.$t("issuing_body"),
                                        },
                                        model: {
                                          value:
                                            _vm.Profile.LegalRepresentative
                                              .Issuer,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile.LegalRepresentative,
                                              "Issuer",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "Profile.LegalRepresentative.Issuer",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("state") },
                                        model: {
                                          value:
                                            _vm.Profile.LegalRepresentative
                                              .IssuerState,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile.LegalRepresentative,
                                              "IssuerState",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "Profile.LegalRepresentative.IssuerState",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        VMenu,
                                        {
                                          ref: "menuDateLegalRepresentative",
                                          attrs: {
                                            "close-on-content-click": false,
                                            "return-value":
                                              _vm.dateLegalRepresentative,
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "min-width": "290px",
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              _vm.dateLegalRepresentative =
                                                $event
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              _vm.dateLegalRepresentative =
                                                $event
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      VTextField,
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm.$t(
                                                                  "expedition_date"
                                                                ),
                                                              "prepend-inner-icon":
                                                                "mdi-calendar",
                                                              readonly: "",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                _vm.Profile.LegalRepresentative.ExpeditionDate =
                                                                  _vm.dateLegalRepresentative.toString() +
                                                                  "T00:00:00"
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.dateLegalRepresentative,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.dateLegalRepresentative =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "dateLegalRepresentative",
                                                            },
                                                          },
                                                          "v-text-field",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1567741753
                                          ),
                                          model: {
                                            value:
                                              _vm.menuDateLegalRepresentative,
                                            callback: function ($$v) {
                                              _vm.menuDateLegalRepresentative =
                                                $$v
                                            },
                                            expression:
                                              "menuDateLegalRepresentative",
                                          },
                                        },
                                        [
                                          _c(VDatePicker, {
                                            staticClass: "mx-auto",
                                            on: {
                                              change: function ($event) {
                                                _vm.$refs.menuDateLegalRepresentative.save(
                                                  _vm.dateLegalRepresentative
                                                )
                                                _vm.menuDateLegalRepresentative = false
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.dateLegalRepresentative,
                                              callback: function ($$v) {
                                                _vm.dateLegalRepresentative =
                                                  $$v
                                              },
                                              expression:
                                                "dateLegalRepresentative",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "non_resident_investor_question"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        VRow,
                                        { staticClass: "my-4 mx-12" },
                                        [
                                          _c(
                                            VRadioGroup,
                                            {
                                              attrs: { center: "" },
                                              model: {
                                                value:
                                                  _vm.Profile
                                                    .HasNonResidentInvestor,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.Profile,
                                                    "HasNonResidentInvestor",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "Profile.HasNonResidentInvestor",
                                              },
                                            },
                                            [
                                              _c(VRadio, {
                                                attrs: { value: false },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("no")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1351614811
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: true },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("yes")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  661222869
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.Profile.JuridicalType ==
                    _vm.JuridicalTypeEnum.LegalPerson &&
                  _vm.Profile.HasNonResidentInvestor
                    ? _c(
                        VExpansionPanel,
                        [
                          _c(
                            VExpansionPanelHeader,
                            {
                              attrs: {
                                disabled: _vm.disabled_field,
                                color: "white",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("non_resident_investor")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            VExpansionPanelContent,
                            { attrs: { color: "white" } },
                            [
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("rde") },
                                        model: {
                                          value:
                                            _vm.Profile.NonResidentInvestor.RDE,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile.NonResidentInvestor,
                                              "RDE",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "Profile.NonResidentInvestor.RDE",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: {
                                          label: _vm.$t(
                                            "country_representative"
                                          ),
                                        },
                                        model: {
                                          value:
                                            _vm.Profile.NonResidentInvestor
                                              .CountryLegalRepresentative,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile.NonResidentInvestor,
                                              "CountryLegalRepresentative",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                  Profile.NonResidentInvestor.CountryLegalRepresentative\n                ",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("cpf") },
                                        model: {
                                          value:
                                            _vm.Profile.NonResidentInvestor
                                              .CountryLegalRepresentativeSocialNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile.NonResidentInvestor,
                                              "CountryLegalRepresentativeSocialNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                  Profile.NonResidentInvestor\n                    .CountryLegalRepresentativeSocialNumber\n                ",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: {
                                          label: _vm.$t("tax_representative"),
                                        },
                                        model: {
                                          value:
                                            _vm.Profile.NonResidentInvestor
                                              .TaxRepresentative,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile.NonResidentInvestor,
                                              "TaxRepresentative",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "Profile.NonResidentInvestor.TaxRepresentative",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: { label: _vm.$t("cpf") },
                                        model: {
                                          value:
                                            _vm.Profile.NonResidentInvestor
                                              .TaxRepresentativeSocialNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile.NonResidentInvestor,
                                              "TaxRepresentativeSocialNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                  Profile.NonResidentInvestor.TaxRepresentativeSocialNumber\n                ",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.Profile.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson
                    ? _c(
                        VExpansionPanel,
                        [
                          _c(
                            VExpansionPanelHeader,
                            {
                              staticClass: "dark-color",
                              attrs: {
                                disabled: _vm.disabled_field,
                                color: "white",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("stock_position")) + " "
                              ),
                            ]
                          ),
                          _c(
                            VExpansionPanelContent,
                            { attrs: { color: "white" } },
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "mt-8 mr-9",
                                  attrs: {
                                    color: "primary",
                                    small: "",
                                    rounded: "",
                                    absolute: "",
                                    fab: "",
                                    top: "",
                                    right: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.open_stock_position_dialog(
                                        null
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    VIcon,
                                    { attrs: { small: "", color: "white" } },
                                    [_vm._v("mdi-plus")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: {
                                          type: "number",
                                          prefix: "R$",
                                          label: _vm.$t("net_worth"),
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.Profile.NetWorth = parseFloat(
                                              _vm.Profile.NetWorth
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.Profile.NetWorth,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "NetWorth",
                                              $$v
                                            )
                                          },
                                          expression: "Profile.NetWorth",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass:
                                          "purple-input my-input-style",
                                        attrs: {
                                          type: "number",
                                          prefix: "R$",
                                          label: _vm.$t(
                                            "average_monthly_revenue"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.Profile.AverageMonthlyRevenue =
                                              parseFloat(
                                                _vm.Profile
                                                  .AverageMonthlyRevenue
                                              )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.Profile.AverageMonthlyRevenue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.Profile,
                                              "AverageMonthlyRevenue",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "Profile.AverageMonthlyRevenue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VSimpleTable,
                                {
                                  staticStyle: {
                                    width: "100%",
                                    background: "var(--white)",
                                  },
                                },
                                [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(_vm.$t("name"))),
                                      ]),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(_vm.$t("cpf"))),
                                      ]),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(_vm.$t("nationality"))),
                                      ]),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(_vm.$t("capital"))),
                                      ]),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(_vm.$t("options"))),
                                      ]),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.Profile.StockPositions,
                                      function (sp, index) {
                                        return _c("tr", { key: index }, [
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "text-center dark-color",
                                            },
                                            [_vm._v(_vm._s(sp.Name))]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "text-center dark-color",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(sp.RegistryNumber) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "text-center dark-color",
                                            },
                                            [_vm._v(_vm._s(sp.Nationality))]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "text-center dark-color",
                                            },
                                            [_vm._v(_vm._s(sp.Capital) + "%")]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass:
                                                    "px-2 ml-1 secondary",
                                                  attrs: {
                                                    "min-width": "0",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.open_stock_position_dialog(
                                                        sp
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color: "white",
                                                      },
                                                    },
                                                    [_vm._v("mdi-pencil")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass: "px-2 ml-1",
                                                  attrs: {
                                                    color: "red",
                                                    "min-width": "0",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.open_delete_dialog_stock_position(
                                                        sp
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color: "white",
                                                      },
                                                    },
                                                    [_vm._v("mdi-trash-can")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.Profile.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson
                    ? _c(
                        VExpansionPanel,
                        [
                          _c(
                            VExpansionPanelHeader,
                            {
                              staticClass: "dark-color",
                              attrs: {
                                disabled: _vm.disabled_field,
                                color: "white",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("equity_holding")) + " "
                              ),
                            ]
                          ),
                          _c(
                            VExpansionPanelContent,
                            { attrs: { color: "white" } },
                            [
                              _c(
                                VRow,
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "mt-8 mr-9",
                                      attrs: {
                                        color: "primary",
                                        small: "",
                                        rounded: "",
                                        absolute: "",
                                        fab: "",
                                        top: "",
                                        right: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.open_equity_holding_dialog(
                                            null
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          attrs: { small: "", color: "white" },
                                        },
                                        [_vm._v("mdi-plus")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VSimpleTable,
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        background: "var(--white)",
                                      },
                                    },
                                    [
                                      _c("thead", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v(_vm._s(_vm.$t("name")))]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v(_vm._s(_vm.$t("cpf")))]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("nationality"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v(_vm._s(_vm.$t("capital")))]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "is_politically_exposed"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v(_vm._s(_vm.$t("options")))]
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.Profile.EquityHoldings,
                                          function (eh, index) {
                                            return _c("tr", { key: index }, [
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-center dark-color",
                                                },
                                                [_vm._v(_vm._s(eh.Name))]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-center dark-color",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        eh.RegistryNumber
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-center dark-color",
                                                },
                                                [_vm._v(_vm._s(eh.Nationality))]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-center dark-color",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(eh.Capital) + "%"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-center dark-color",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        eh.PoliticallyExposed
                                                          ? _vm.$t("yes")
                                                          : _vm.$t("no")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _c(
                                                    VBtn,
                                                    {
                                                      staticClass:
                                                        "px-2 ml-1 secondary",
                                                      attrs: {
                                                        "min-width": "0",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.open_equity_holding_dialog(
                                                            eh
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            color: "white",
                                                          },
                                                        },
                                                        [_vm._v("mdi-pencil")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VBtn,
                                                    {
                                                      staticClass: "px-2 ml-1",
                                                      attrs: {
                                                        color: "red",
                                                        "min-width": "0",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.open_delete_dialog_equity_holding(
                                                            eh
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            color: "white",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-trash-can"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.Profile.JuridicalType ==
                  _vm.JuridicalTypeEnum.NaturalPerson
                    ? _c(
                        VExpansionPanel,
                        [
                          _c(
                            VExpansionPanelHeader,
                            {
                              staticClass: "dark-color",
                              attrs: {
                                disabled: _vm.disabled_field,
                                color: "white",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("professional_info")) + " "
                              ),
                            ]
                          ),
                          _c(
                            VExpansionPanelContent,
                            { attrs: { color: "white" } },
                            [
                              _vm.Profile.Type == _vm.UserTypeEnum.Investor
                                ? _c(
                                    VRow,
                                    [
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "4" } },
                                        [
                                          _c(VAutocomplete, {
                                            staticClass:
                                              "field purple-input my-input-style",
                                            attrs: {
                                              filter: _vm.filterObject,
                                              items: _vm.profession_options,
                                              "item-text":
                                                _vm.translateProfessionOptions,
                                              "item-value": "id",
                                              label: _vm.$t("profession"),
                                              id: "profession_input",
                                            },
                                            on: { keyup: _vm.getProfession },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "no-data",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        VListItem,
                                                        [
                                                          _c(
                                                            VListItemTitle,
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.profession_text_follback
                                                                      ? _vm.$t(
                                                                          "write_your_profession"
                                                                        )
                                                                      : _vm.$t(
                                                                          "profession_not_found"
                                                                        )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              3741272751
                                            ),
                                            model: {
                                              value: _vm.Profile.ProfessionId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.Profile,
                                                  "ProfessionId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "Profile.ProfessionId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "4" } },
                                        [
                                          _c(VTextField, {
                                            staticClass:
                                              "purple-input my-input-style",
                                            attrs: {
                                              label: _vm.$t(
                                                "professional_occupation"
                                              ),
                                            },
                                            model: {
                                              value:
                                                _vm.Profile
                                                  .ProfessionalOccupation,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.Profile,
                                                  "ProfessionalOccupation",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "Profile.ProfessionalOccupation",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "4" } },
                                        [
                                          _c(VTextField, {
                                            staticClass:
                                              "purple-input my-input-style",
                                            attrs: {
                                              label: _vm.$t("employer"),
                                            },
                                            model: {
                                              value: _vm.Profile.Employer,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.Profile,
                                                  "Employer",
                                                  $$v
                                                )
                                              },
                                              expression: "Profile.Employer",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c(VTextField, {
                                            staticClass:
                                              "purple-input my-input-style",
                                            attrs: {
                                              label: _vm.$t("company_id"),
                                            },
                                            model: {
                                              value: _vm.Profile.EmployerNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.Profile,
                                                  "EmployerNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "Profile.EmployerNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c(VTextField, {
                                            staticClass:
                                              "purple-input my-input-style",
                                            attrs: { label: _vm.$t("phone") },
                                            model: {
                                              value: _vm.Profile.EmployerPhone,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.Profile,
                                                  "EmployerPhone",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "Profile.EmployerPhone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c(VTextField, {
                                            staticClass:
                                              "purple-input my-input-style",
                                            attrs: { label: _vm.$t("address") },
                                            model: {
                                              value:
                                                _vm.Profile.EmployerAddress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.Profile,
                                                  "EmployerAddress",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "Profile.EmployerAddress",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c(VTextField, {
                                            staticClass:
                                              "purple-input my-input-style",
                                            attrs: {
                                              type: "number",
                                              label: _vm.$t("monthly_income"),
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.Profile.MonthlyIncome =
                                                  parseFloat(
                                                    _vm.Profile.MonthlyIncome
                                                  )
                                              },
                                            },
                                            model: {
                                              value: _vm.Profile.MonthlyIncome,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.Profile,
                                                  "MonthlyIncome",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "Profile.MonthlyIncome",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    VExpansionPanel,
                    [
                      _vm.Profile.JuridicalType ==
                      _vm.JuridicalTypeEnum.NaturalPerson
                        ? _c(
                            VExpansionPanelHeader,
                            {
                              staticClass: "dark-color",
                              attrs: {
                                color: "white",
                                disabled: _vm.disabled_field,
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("statements")) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        VExpansionPanelContent,
                        { attrs: { color: "white" } },
                        [
                          _vm.Profile.Type == _vm.UserTypeEnum.Investor
                            ? _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "dark-color" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("marital_status")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        VRow,
                                        { staticClass: "my-4 mx-12" },
                                        [
                                          _c(
                                            VRadioGroup,
                                            {
                                              attrs: { center: "" },
                                              model: {
                                                value:
                                                  _vm.Profile.MaritalStatus,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.Profile,
                                                    "MaritalStatus",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "Profile.MaritalStatus",
                                              },
                                            },
                                            [
                                              _c(VRadio, {
                                                attrs: { value: 0 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "single"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  127828032
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 1 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "married"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  705419646
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 2 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "widower"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1329492943
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 3 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "divorced"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2925929822
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "dark-color" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("sex")) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        VRow,
                                        { staticClass: "my-4 mx-12" },
                                        [
                                          _c(
                                            VRadioGroup,
                                            {
                                              attrs: { center: "" },
                                              model: {
                                                value: _vm.Profile.Sex,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.Profile,
                                                    "Sex",
                                                    $$v
                                                  )
                                                },
                                                expression: "Profile.Sex",
                                              },
                                            },
                                            [
                                              _c(VRadio, {
                                                attrs: { value: 0 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "woman"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  496421856
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 1 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("man")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3434246680
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 2 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "other"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1226935582
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "dark-color" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("is_politically_exposed")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        VRow,
                                        { staticClass: "my-4 mx-12" },
                                        [
                                          _c(
                                            VRadioGroup,
                                            {
                                              attrs: { center: "" },
                                              model: {
                                                value:
                                                  _vm.Profile
                                                    .PoliticallyExposed,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.Profile,
                                                    "PoliticallyExposed",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "Profile.PoliticallyExposed",
                                              },
                                            },
                                            [
                                              _c(VRadio, {
                                                attrs: { value: true },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("yes")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  661222869
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: false },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("no")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1351614811
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "dark-color" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("is_us_person")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        VRow,
                                        { staticClass: "my-4 mx-12" },
                                        [
                                          _c(
                                            VRadioGroup,
                                            {
                                              attrs: { center: "" },
                                              model: {
                                                value: _vm.Profile.USPerson,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.Profile,
                                                    "USPerson",
                                                    $$v
                                                  )
                                                },
                                                expression: "Profile.USPerson",
                                              },
                                            },
                                            [
                                              _c(VRadio, {
                                                attrs: { value: true },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("yes")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  661222869
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: false },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("no")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1351614811
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "text-right mt-6" },
            [
              _c(
                VBtn,
                {
                  staticClass: "mr-0 white-color",
                  attrs: {
                    color: "primary",
                    type: "submit",
                    disabled: _vm.disabled_field,
                    loading: _vm.loading,
                  },
                },
                [_vm._v(_vm._s(_vm.$t("update_profile")))]
              ),
            ],
            1
          ),
          _vm.alert_msg != null
            ? _c(
                VAlert,
                {
                  staticClass: "ma-2",
                  attrs: { type: _vm.success ? "success" : "error" },
                },
                [_vm._v(" " + _vm._s(_vm.$t(_vm.alert_msg)) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.dialogEquityHolding
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.dialogEquityHolding,
                callback: function ($$v) {
                  _vm.dialogEquityHolding = $$v
                },
                expression: "dialogEquityHolding",
              },
            },
            [
              _c("AddEquityHolding", {
                attrs: { Obj: _vm.equityHolding, UserId: _vm.Profile.Id },
                on: {
                  update: _vm.update_equity_holding,
                  close: function ($event) {
                    _vm.dialogEquityHolding = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.dialogStockPosition
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.dialogStockPosition,
                callback: function ($$v) {
                  _vm.dialogStockPosition = $$v
                },
                expression: "dialogStockPosition",
              },
            },
            [
              _c("AddStockPosition", {
                attrs: { Obj: _vm.stockPosition, UserId: _vm.Profile.Id },
                on: {
                  update: _vm.update_stock_position,
                  close: function ($event) {
                    _vm.dialogStockPosition = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.deleteDialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.deleteDialog,
                callback: function ($$v) {
                  _vm.deleteDialog = $$v
                },
                expression: "deleteDialog",
              },
            },
            [
              _c("DeleteConfirmationModal", {
                attrs: {
                  obj: _vm.equityHolding
                    ? _vm.equityHolding
                    : _vm.stockPosition,
                  name: _vm.equityHolding
                    ? _vm.$t("this_equity_holding")
                    : _vm.$t("this_stock_position"),
                },
                on: {
                  close: function ($event) {
                    _vm.deleteDialog = false
                  },
                  delete: _vm.delete_obj,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }