import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.all_loaded
    ? _c(
        VContainer,
        { attrs: { id: "profile", fluid: "", tag: "section" } },
        [
          _c(
            VRow,
            { attrs: { justify: "center" } },
            [
              _c(
                VCol,
                {
                  attrs: {
                    cols: "12",
                    md: _vm.profile.Type != _vm.UserTypeEnum.Admin ? 8 : 12,
                  },
                },
                [
                  _c(VHover, {
                    attrs: { "close-delay": "200" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var hover = ref.hover
                            return [
                              _c(
                                "div",
                                {
                                  class: {
                                    "on-hover": hover,
                                    profileImageInvestor:
                                      _vm.profile.Type ==
                                      _vm.UserTypeEnum.Investor,
                                    profileImageOther:
                                      _vm.profile.Type !=
                                      _vm.UserTypeEnum.Investor,
                                  },
                                  attrs: { elevation: hover ? 16 : 2 },
                                  on: { click: _vm.openFilePicker },
                                },
                                [
                                  _vm.profile.Base64Image != null ||
                                  _vm.profile.Image != null
                                    ? _c(
                                        VImg,
                                        {
                                          staticStyle: {
                                            cursor: "pointer",
                                            "border-radius": "5%",
                                            height: "100%",
                                            display: "flex",
                                            "justify-content": "center",
                                          },
                                          attrs: { src: _vm.showPhoto() },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                height: "100%",
                                                width: "100%",
                                                display: "flex",
                                                "justify-content": "center",
                                                "flex-direction": "column",
                                              },
                                            },
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass: "ma-auto",
                                                  class: { "show-btns": hover },
                                                  attrs: {
                                                    color: "transparent",
                                                    icon: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      class: {
                                                        "show-btns": hover,
                                                      },
                                                      attrs: {
                                                        "x-large": "",
                                                        color: "transparent",
                                                      },
                                                    },
                                                    [_vm._v(" mdi-pencil ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _c(
                                        VImg,
                                        {
                                          staticStyle: {
                                            cursor: "pointer",
                                            "border-radius": "5%",
                                          },
                                          attrs: {
                                            src: require("../../../assets/profile.png"),
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                height: "100%",
                                                width: "100%",
                                                display: "flex",
                                                "justify-content": "center",
                                                "flex-direction": "column",
                                              },
                                            },
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass: "ma-auto",
                                                  class: { "show-btns": hover },
                                                  attrs: {
                                                    color: "transparent",
                                                    icon: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      class: {
                                                        "show-btns": hover,
                                                      },
                                                      attrs: {
                                                        "x-large": "",
                                                        color: "transparent",
                                                      },
                                                    },
                                                    [_vm._v(" mdi-pencil ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                  _c("input", {
                                    ref: "imageProfileInput",
                                    staticStyle: { display: "none" },
                                    attrs: {
                                      id: "imageProfileInput",
                                      name: "imageProfileInput",
                                      type: "file",
                                      accept:
                                        "image/png, image/jpeg, image/bmp",
                                    },
                                    on: { change: _vm.setImageProfile },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3549108982
                    ),
                  }),
                  _c(
                    VCard,
                    { staticClass: "profileCard", attrs: { color: "white" } },
                    [
                      _c(
                        "h3",
                        { staticClass: "h4 profile-header dark-color" },
                        [_vm._v(_vm._s(_vm.showName()))]
                      ),
                      _c(
                        VTabs,
                        {
                          attrs: {
                            "show-arrows": "",
                            "background-color": "white",
                          },
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        [
                          _c(
                            VTab,
                            {
                              attrs: {
                                "data-test": "Investor:Profile:PersonalDataTab",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("personal_data")))]
                          ),
                          _vm.profile.Type == _vm.UserTypeEnum.Investor
                            ? _c(
                                VTab,
                                {
                                  attrs: {
                                    "data-test":
                                      "Investor:Profile:PortfolioProfileTab",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("portfolio_profile")))]
                              )
                            : _vm._e(),
                          _vm.profile.Type == _vm.UserTypeEnum.Investor
                            ? _c(
                                VTab,
                                {
                                  attrs: {
                                    "data-test": "Investor:Profile:FilesTab",
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("files")) + " ")]
                              )
                            : _vm._e(),
                          _vm.profile.Type == _vm.UserTypeEnum.Investor
                            ? _c(
                                VTab,
                                {
                                  attrs: {
                                    "data-test":
                                      "Investor:Profile:StatementTab",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("statements")) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.tab == _vm.profileTabsEnums.UserData
                        ? _c("Basic", { attrs: { Profile: _vm.profile } })
                        : _vm._e(),
                      _c(
                        "div",
                        [
                          _vm.tab ===
                            _vm.profileTabsEnums.UserPortfolioPreferences &&
                          !_vm.userPreferencesLoading
                            ? _c("UserPreferences", {
                                attrs: {
                                  editable: false,
                                  loading: _vm.userPreferencesLoading,
                                  btnText: "save",
                                  User: _vm.profile,
                                  userType: _vm.UserTypeEnum.Investor,
                                },
                                on: { btnConfirm: _vm.updatePreferences },
                              })
                            : _vm._e(),
                          _vm.userPreferencesLoading
                            ? _c(
                                "div",
                                { staticClass: "items-center" },
                                [
                                  _c(VProgressCircular, {
                                    attrs: {
                                      indeterminate: "",
                                      size: "60",
                                      color: "primary",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.tab == _vm.profileTabsEnums.UserFiles
                        ? _c("Files", { attrs: { Profile: _vm.profile } })
                        : _vm._e(),
                      _vm.tab == _vm.profileTabsEnums.UserStatements
                        ? _c("DashboardReportTable", {
                            attrs: {
                              userId: _vm.profile.Id,
                              reportType: _vm.ReportTypeEnum.Annual,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.profile.Type == _vm.UserTypeEnum.Partner
                ? _c(
                    VCol,
                    { attrs: { cols: "12", md: "4" } },
                    [_c("Partner", { attrs: { Profile: _vm.profile } })],
                    1
                  )
                : _vm._e(),
              _vm.profile.Type == _vm.UserTypeEnum.Investor
                ? _c(
                    VCol,
                    { attrs: { cols: "12", md: "4" } },
                    [_c("BankAccounts", { attrs: { Profile: _vm.profile } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }