import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.Profile.PartnerAuthToken
    ? _c(
        VCard,
        [
          _c(
            VCardText,
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { attrs: { sm: "12", md: "12" } },
                    [
                      _c("p", { staticClass: "colorpickerTitle" }, [
                        _vm._v(_vm._s(_vm.$t("partner_auth_token"))),
                      ]),
                      _c(VTextField, {
                        attrs: {
                          "append-icon": _vm.show ? "mdi-eye-off" : "mdi-eye",
                          type: _vm.show ? "text" : "password",
                        },
                        on: {
                          "click:append": function () {
                            return (_vm.show = !_vm.show)
                          },
                        },
                        model: {
                          value: _vm.Profile.PartnerAuthToken,
                          callback: function ($$v) {
                            _vm.$set(_vm.Profile, "PartnerAuthToken", $$v)
                          },
                          expression: "Profile.PartnerAuthToken",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "partner-copy-clipboard" },
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.copy_to_clipboard()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("click_copy_auth_token")) +
                                  " "
                              ),
                            ]
                          ),
                          _c("font-awesome-icon", {
                            attrs: {
                              icon: "fa-solid fa-copy",
                              width: "16px",
                              height: "16px",
                              color: "var(--primary)",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.copy_to_clipboard()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }