import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(VSimpleTable, { staticStyle: { background: "var(--white)" } }, [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "dark-color" }, [
              _vm._v(_vm._s(_vm.$t("name"))),
            ]),
            _c("th", { staticClass: "text-center dark-color" }, [
              _vm._v(_vm._s(_vm.$t("document_type"))),
            ]),
            _c("th", { staticClass: "text-center dark-color" }, [
              _vm._v(" " + _vm._s(_vm.$tc("company", 1)) + " "),
            ]),
            _c("th", { staticClass: "text-center dark-color" }, [
              _vm._v(" " + _vm._s(_vm.$t("download")) + " "),
            ]),
          ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.Profile.Files, function (file, i) {
            return _c("tr", { key: i }, [
              _c("td", { staticClass: "dark-color" }, [
                _vm._v(_vm._s(file.FileName)),
              ]),
              _c("td", { staticClass: "text-center dark-color" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      file.UserFileType
                        ? _vm.$t(_vm.documentTypeText(file.UserFileType))
                        : "-"
                    ) +
                    " "
                ),
              ]),
              _c("td", { staticClass: "text-center dark-color" }, [
                _vm._v(" " + _vm._s(file.CompanyName) + " "),
              ]),
              _c(
                "td",
                { staticClass: "text-center" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "px-2 ml-1 btnDownloadFile",
                      attrs: {
                        icon: "",
                        color: "primary",
                        "min-width": "0",
                        small: "",
                        loading: _vm.fileDownloading == file.Path,
                        "data-test":
                          "Investor:Files:Download_" + file.UserFileType,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.download_contract(file, file.FileName)
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { small: "" } }, [
                        _vm._v("mdi-download"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }