import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { attrs: { color: "white" } },
    [
      _c(
        VCardTitle,
        [
          _c(VIcon, [_vm._v("mdi-bank")]),
          _c("h3", { staticClass: "h5 ml-2 dark-color" }, [
            _vm._v(_vm._s(_vm.$t("bank_acc"))),
          ]),
        ],
        1
      ),
      _c(
        VCardText,
        { staticClass: "text-left" },
        [
          _c(
            VList,
            { attrs: { color: "white" } },
            _vm._l(this.user_bank_accounts, function (acc, i) {
              return _c(
                VListItem,
                {
                  key: i,
                  staticClass: "bank-acc",
                  on: {
                    click: function ($event) {
                      return _vm.open_bank_dialog(acc)
                    },
                  },
                },
                [
                  _c(
                    VListItemContent,
                    [
                      _c(VListItemTitle, { staticClass: "dark-color" }, [
                        _vm._v(_vm._s(_vm.get_bank_name(acc))),
                      ]),
                      !acc.IsForeign
                        ? _c(VListItemSubtitle, [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("account")) +
                                  " " +
                                  _vm._s(acc.Account)
                              ),
                            ]),
                          ])
                        : _c(VListItemSubtitle, [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("beneficiary")) +
                                  " " +
                                  _vm._s(acc.Beneficiary)
                              ),
                            ]),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    VListItemAction,
                    [
                      _c(
                        VIcon,
                        {
                          staticClass: "edit-btn",
                          attrs: { color: "grey lighten-1" },
                        },
                        [_vm._v("mdi-pencil")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                VBtn,
                {
                  staticClass: "mr-0 mt-6 white-color",
                  attrs: { color: "primary", rounded: "" },
                  on: {
                    click: function ($event) {
                      return _vm.open_bank_dialog(null)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("new_bank_acc")))]
              ),
            ],
            1
          ),
          _vm.dialog
            ? _c(
                VDialog,
                {
                  attrs: { scrollable: "" },
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c("AddBankAccountModal", {
                    attrs: { BankAcc: _vm.bank_acc },
                    on: {
                      close: function ($event) {
                        _vm.dialog = false
                      },
                      add: _vm.add_account,
                      edit: _vm.edit_account,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }